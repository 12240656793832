<template>
    <nav role="navigation" class="commands-buttons-list">
        <menu-navigation-category
            :buttons="optionsButtons"
            class="options-category"
        >
          <template v-slot:extend v-if="isExtended()">
            <img :alt="platformName" :src="logoUri" />
          </template>
        </menu-navigation-category>

        <MenuNavigationCategory
            :buttons="principalButtons"
            class="principal-category"
        />

        <MenuNavigationCategory
            :buttons="projetButtons"
            class="project-category"
        />

        <MenuNavigationCategory
            :buttons="parameterButtons"
            class="parameter-category"
        />
    </nav>
</template>

<script setup lang="ts">
import MenuNavigationCategory from '../MenuNavigationCategory/MenuNavigationCategory.vue';

import { useNavigationData } from '@/composables/useNavigationData';
const appProps = inject('appProps') as AppProps
const {menuNavigation} = useNavigationData(appProps.isAdmin);

import {useButtonFilter} from '@/composables/useButtonFilter';
import {inject, Ref, ref} from "vue";
import {AppProps} from "@/types/menuNavigation";

const {principalButtons, projetButtons, parameterButtons, optionsButtons} = useButtonFilter(menuNavigation.menuButtons);

import {useEnvVariables} from "@/composables/useEnvVariables";
import useMenuState from "@/composables/useMenuState";

const { environmentVariables } = useEnvVariables();
const platformName = `logo de la plateforme ${environmentVariables.pf_name.value}`;
const logoUri: Ref<string> = ref(
    environmentVariables.hostname.value.length > 0
    ? `${window.location.protocol}//${environmentVariables.hostname.value}${environmentVariables.basePath.value}/img${environmentVariables.platformLogo.value}`
    : `${environmentVariables.basePath.value}/img${environmentVariables.platformLogo.value}`);

const { isExtended } = useMenuState();
</script>