import { ref } from 'vue';
import { buttons } from '@/components/MenuNavigation/data/buttons';
import { buttonCode } from '@/components/MenuNavigation/data/buttonCode';
import { category } from '@/components/MenuNavigation/data/category';

import MenuNavigationModel from '@/components/MenuNavigation/types/model';

import type { MenuNavigation } from '@/components/MenuNavigation/types/interfaces';
import type {setButtonNotificationType} from "@/types/menuNavigation";

const menuNavigation:MenuNavigation = {
    activeButton: ref(buttonCode.espaces),
    menuButtons: buttons.map((data) => new MenuNavigationModel(data)),
}

export function useNavigationData(isAdmin: boolean){
  const findButtonByCode = (code: string) : MenuNavigationModel | null => {
    const buttonIndex = menuNavigation.menuButtons.findIndex(button => button.code.value === code)

    if(buttonIndex === -1){
      displayWarnMessage(`Button not found : ${code}`)
      return null;
    }

    return menuNavigation.menuButtons[buttonIndex]
  }

  if(isAdmin){
    const button = findButtonByCode(buttonCode.administration)
    if( button ) button.isShown.value = isAdmin
  }

  const setButtonActive = (code: string): void => {

    const button = findButtonByCode(code);

    if ( 
      button 
      && button.category.value !== category.options
    ) menuNavigation.activeButton.value = code;

  }

  const setButtonDisplay = (code: string, state: boolean) => {

    const button = findButtonByCode(code);

    if ( button ) button.isShown.value = state;

  }

  const isButtonActive = ((code:string) => {
    return menuNavigation.activeButton.value === code;
  });

  const setButtonNotification: setButtonNotificationType = (code, state) => {

    const button = findButtonByCode(code);

    if (button) button.hasNotification.value = state;

  }

  return {
    menuNavigation, 
    isButtonActive, 
    setButtonActive, 
    setButtonNotification, 
    setButtonDisplay, 
    findButtonByCode
  }
}


function displayWarnMessage(message: string){
    console.warn(message)
}
